<template>
  <div class="infoPage"
       v-infinite-scroll="loadMore"
       :infinite-scroll-disabled="busy"
       :infinite-scroll-distance="50"
       :infinite-scroll-immediate="false"
  >
    <div class="conBoxList">
      <!--      <div class="list">-->
      <div class="item" v-for="(item,index) in datalist" :key="item.sightseeingId" @click="go(item.sightseeingId)">
        <div class="img scale">
          <img :src="item.sightseeingCover" alt="">
          <div class="eye el-icon-view">&nbsp;{{ item.totalVisitNum }}</div>
        </div>
        <div class="con">
          <div class="tit">
            {{ item.webTitle }}
          </div>
          <div class="text2"><span v-for="(items,indexs) in item.tags" v-if="indexs<2">{{items}}</span><span v-if="item.tags.length>2">...</span></div>
          <div class="conText">
            {{ item.webDesc }}
          </div>
        </div>
      </div>
      <!--      </div>-->
    </div>

    <!--    <div class="paging-device">
          <el-pagination
              layout="prev, pager, next"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="current_page"
              :page-size="per_page"
              :total="totalCount">
          </el-pagination>
        </div>-->
  </div>
</template>

<script>
import {getSightseeingList} from '@/api/table'
export default {
  name: "lishi",
  components:{
  },
  data(){
    return {
      // imgURL,
      url:'',
      totalCount: 0,
      current_page: 1,
      per_page: 10,
      datalist:[],
      busy: false,
      timer:null
    }
  },
  created() {

  },
  mounted() {
    this.createlist()
  },
  methods:{
    loadMore: function() {
      clearTimeout(this.timer)
      this.busy = true
      if(this.totalCount > this.datalist.length){
        this.timer = setTimeout(()=>{
          this.current_page++
          this.createlist()
        },1000)
      }else{
        return
      }

    },
    async createlist(){
      if(this.current_page == 1){
        this.datalist = []
      }
      await getSightseeingList({
        sightseeingType:'29',   // 必填 玩转度假区类型 26运河有景 27运河游素 28运河有味 29运河有礼 52运河有趣
        // sightseeingOnline:'1',   // 是否上线 0否 1是
        pageNum:this.current_page,
        pageSize:this.per_page
      }).then(res=>{
        console.log(res)
        if (res.code==200) {
          res.rows.forEach((item,index)=>{
            if(item.tags && item.tags.trim().length>0){item.tags = item.tags.split(',')}
            // item.tags = item.tags.split(',')
          })
          this.datalist.push(...res.rows)
          this.busy = false
          this.totalCount=res.total
        }
      })
    },
    go(id){
      this.$router.push({path:'/playturn/haveSceneryInfo',query:{id,type:'29'}})
    },
    select(){

    },
    changep(){

    }
  }
}
</script>

<style scoped lang="less">
.infoPage{
  width: 100%;
  min-height: 800px;
  //padding: 0 0 0 55px;
  .conBoxList{
    display: flex;
    flex-wrap: wrap;
    width: 1200px;
    margin: auto;
    padding: 60px 0 120px 0;
    .item{
      flex: none;
      width: 380px;
      background: #FFFFFF;
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      margin-left: 30px;
      margin-bottom: 60px;
      &:nth-child(3n+1){
        margin-left: 0;
      }
      &:hover{
        .img{
          transition:all 1000ms ease;
          //transform: scale(1);
          transform: scale(1.1);
        }
        .con>.tit{
          color: #337D8D;
        }
      }
      .img{
        width: 100%;
        height: 300px;
        position: relative;
        transition:all 500ms ease;
        img{
          width: 100%;
          height: 100%;
        }
        .eye{
          position: absolute;
          top: 16px;
          left: 16px;
          padding: 8px 12px;
          background: #337D8D55;
          border-radius: 5px;
          font-size: 14px;
          font-family: STHeitiSC-Medium, STHeitiSC;
          font-weight: 600;
          color: #ffffff;
        }
      }
      .con{
        padding: 24px;
        .tit{
          font-size: 20px;
          font-family: STSongti-SC-Black, STSongti-SC;
          font-weight: 900;
          color: #333333;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1; //行数
          overflow: hidden;
        }
        .text2{
          margin: 15px 0 0px;
          display: flex;
          align-items: center;
          width: 100%;
          flex-wrap: wrap;
          span{
            font-size: 12px;
            font-family: STSongti-SC-Regular, STSongti-SC;
            font-weight: 400;
            color: #333333;
            padding: 3px 13px 4px 12px;
            margin-right: 10px;
            margin-bottom: 10px;
            background: rgba(120, 120, 120, 0.1);
            border-radius: 17px;
          }
        }
        .conText{
          margin-top: 10px;
          font-size: 14px;
          font-family: STSongti-SC-Regular, STSongti-SC;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2; //行数
          overflow: hidden;
        }
      }

    }
  }


}
</style>

